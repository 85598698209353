<script>
/**
 * List with lazy load store page and checkable support
 * @author Evgeny Shevtsov, info@sitespring.ru
 * @homepage http://sitespring.ru
 * @licence Proprietary
 */

import BaseStore from "../models/BaseStore";
import filter from "lodash/filter";
import isString from "lodash/isString";
import debounce from "lodash/debounce";
import Observer from 'vue-intersection-observer'


export default {
  name: "LazyList",
  components: {
    Observer
  },
  props: {
    store: {
      type: BaseStore,
      required: true
    },
    createRoute: {
      type: String | Object
    },
    detailsRoute: {
      type: String | Object
    },
    filterable: {
      type: Boolean,
      default: true
    },
    filterPlaceholder: {
      default: 'Название...'
    },
    nameProp: {
      default: 'name'
    },

    /**
     * Determine if checkbox at first row will be present
     * */
    isCheckable: {
      type: Boolean,
      default: false
    },
    isRefreshable: {
      type: Boolean,
      default: false
    },

    /**
     * Method to use build meta string
     * @param {BaseModel} record
     * */
    getMetaString: {
      type: Function,
      default: (record) => ""
    },

    toolsWidth: {
      type: String | Number,
      default: 35
    },

    toolbarStyle: Object
  },

  data() {
    return {
      filterText: "",
      needData: null,
      selectedModels: [],
    }
  },

  computed: {
    filteredModels: vm => filter(vm.store.models, m => m.name.toLowerCase().indexOf(vm.filterText.toLowerCase()) > -1),
    isToolbarVisible: vm => vm.isCheckable ? !vm.selectedModels.length : (vm.filterable || vm.createRoute),
    isToolbarAlternateVisible: vm => vm.isCheckable && vm.selectedModels.length > 0
  },

  methods: {
    async prepareData(opts) {
      if (this.store.loading) {
        return false;
      }
      if (this.store.isPaginated()) {
        if (this.store.isLastPage()) {
          return false;
        }
      } else {
        this.store.page(1);
      }

      try {
        await this.store.fetch(opts);
      } catch (e) {
        this.$message.error("Ошибка загрузки данных");
      }
    },

    getDetailsRoute(record) {
      let route = this.detailsRoute;
      if (route) {
        if (isString(route)) {
          route = {
            name: route
          };
        }

        return {
          ...route,
          params: {
            id: record && record.id || null
          }
        }
      }
      return null;
    },

    onObserveChange(entry, unobserve) {
      if (entry.isIntersecting) {
        this.prepareData();
      }
    },

    onRowSelect(currentRow, oldCurrentRow) {
      // assume if checkable use route-link on record`s name instead
      if (this.isCheckable) {
        return;
      }

      let route = this.getDetailsRoute(currentRow);
      if (route) {
        this.$router.push(route);
      }
      this.$emit('rowselect', currentRow);
    },

    handleSelectionChange(val) {
      this.selectedModels = val;
      this.$emit('selectionchange', val);
    },

    onAddClick() {
      let route = this.createRoute;
      if (route) {
        if (isString(route)) {
          route = {
            name: route
          };
        }

        this.$router.push({
          ...route
        });
      }
    },

    onRefreshClick() {
      this.store.reload({
        params: {
          filterText: this.filterText
        }
      });
    }
  },

  created() {
    /**
     * While input bind to store.filterText
     * reload the store cause new request with new filterText value
     * */
    if (this.filterable) {
      this.onFilterChange = debounce(this.onRefreshClick, 500);
    }
  }
}
</script>


<template>
  <div class="datalist" :class="{'datalist--checkable':isCheckable}">

    <div class="toolbar"
         :style="toolbarStyle"
         v-if="isToolbarVisible">
      <el-input v-if="filterable"
                class="grow"
                :placeholder="filterPlaceholder"
                @input="onFilterChange"
                clearable
                v-model="filterText">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>

      <div v-if="isRefreshable"
           class="toolbar-item">
        <el-tooltip content="Обновить данные" class="toolbar-item">
          <el-button icon="el-icon-refresh"
                     @click="onRefreshClick"></el-button>
        </el-tooltip>
      </div>
      <div v-if="createRoute"
           class="toolbar-item">
        <el-tooltip content="Добавить"
                    class="toolbar-item">
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="onAddClick">Добавить
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <slot name="toolbar">
      <!-- custom extra toolbar -->
    </slot>

    <div v-if="isToolbarAlternateVisible"
         class="toolbar alternate">
      <slot name="toolbar-alternate" :selectedModels="selectedModels">
        <div class="toolbar-item">Выбрано {{ selectedModels.length }}:</div>
      </slot>
    </div>

    <el-table :show-header="isCheckable"
              highlight-current-row
              @current-change="onRowSelect"
              @selection-change="handleSelectionChange"
              :data="store.models">

      <el-table-column v-if="isCheckable"
                       type="selection"
                       width="45">
      </el-table-column>

      <el-table-column>
        <template v-slot="{row:record}">
          <slot name="item" :record="record">
            <div class="item-name" v-if="!isCheckable">{{ record[nameProp] }}</div>
            <router-link v-else
                         :to="getDetailsRoute(record)"
                         class="item-name">{{ record[nameProp] }}
            </router-link>

            <div class="metas">{{ getMetaString(record) }}</div>
          </slot>
        </template>
      </el-table-column>

      <el-table-column :width="toolsWidth">
        <template v-slot="{row:record}">
          <slot name="tools" :record="record">
            <el-icon v-if="!isCheckable"
                     class="el-icon-arrow-right"></el-icon>
          </slot>
        </template>
      </el-table-column>
    </el-table>

    <observer @on-change="onObserveChange">
      <p v-loading="true" v-if="store.loading">Загрузка...</p>
    </observer>
  </div>
</template>


<style lang="scss">
@import "../scss/element";

.datalist {
  &:not(&--checkable) {
    .el-table {
      &__row {
        cursor: pointer;
      }
    }
  }

  .item-name {
    font-size: 1rem;
    font-weight: 400;
    color: $--color-primary;
  }

  .metas {
    font-size: .7rem;
    line-height: .7rem;
    color: gray;
  }
}
</style>